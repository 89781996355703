<template>
	<v-app class="admin">
		<loading
			:active.sync="isLoading"
			:can-cancel="false"
			:is-full-page="true"
			:width="80"
			:height="80"
			:opacity="0.7"
			color="cornflowerblue"
		></loading>

		<!--  左サイドバー -->
		<v-navigation-drawer class="fs_left_bar" app v-model="drawer" :width="330">
			<v-container>
				<!-- project name -->
				<v-list-item class="fs_project_title">
					<v-list-item-content>
						<v-list-item-title
							>管理メニュー
							<v-btn icon @click.stop="help = !help"
								><v-icon>{{ "mdi-help-circle" }}</v-icon></v-btn
							>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<!-- project name END -->

				<v-btn class="close" @click="drawer = !drawer"
					><v-icon>{{ "mdi-close" }}</v-icon></v-btn
				>
				<v-simple-table>
					<tbody>
						<tr
							v-for="item in menu"
							:key="item.id"
							@click="clickRow(item)"
							v-bind:class="[
								item.id.toLowerCase() == $route.path.split('/')[2]
									? 'row-selected'
									: 'none',
							]"
						>
							<td></td>
							<td>
								<span>{{ item.name }}</span>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-container>
		</v-navigation-drawer>
		<!--  左サイドバーEND -->

		<!--  トップバー -->
		<v-app-bar app dense flat>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-row justify="center" align="center">
				<v-toolbar-title
					class="logo"
					@click="$router.push('/')"
				></v-toolbar-title>
			</v-row>

			<v-toolbar-items>
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-avatar v-on="on" size="36">
							<img :src="login.image" :alt="login.name" />
						</v-avatar>
					</template>
					<v-list>
						<v-subheader>マイアカウント</v-subheader>
						<v-list-item link @click.stop="$router.push('/mypage')">
							<v-list-item-icon>
								<v-icon>{{ "mdi-account" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>マイページ</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item link @click.stop="$router.push('/admin/project')">
							<v-list-item-icon>
								<v-icon>{{ "mdi-account-supervisor" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>管理画面</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item link @click.stop="logoutDialog = true">
							<v-list-item-icon>
								<v-icon>{{ "mdi-logout" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>ログアウト</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-app-bar>
		<!--  トップバー END-->

		<v-main>
			<router-view :key="$route.fullPath" @loadShowHide="triggerLoading" />
		</v-main>

		<!-- modal -->
		<div class="text-center">
			<v-dialog v-model="logoutDialog" width="500">
				<v-card>
					<v-card-title class="headline"> マイアカウント </v-card-title>
					<v-card-text>ログアウトしますか？</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="logoutDialog = false">
							いいえ
						</v-btn>
						<v-btn color="green darken-1" text @click="logout"> はい </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="help"
				transition="dialog-top-transition"
				max-width="800"
			>
				<template v-slot:default="dialog">
					<v-card>
						<v-toolbar color="darkgray" dark>FieldShareの基本操作</v-toolbar>
						<v-card-text
							style="
								width: 90%;
								margin: 20px;
								text-align: left;
								font-size: 15px;
							"
						>
						<div style="color: #444;">
							<p>1. 「地点一覧」より、新規で地点を追加</p>
							<p>
								2.
								「カメラ一覧」より、カメラを選択して上記で作成した地点を選択して保存
							</p>
							<p>
								3.
								「プロジェクト一覧」より、新規でプロジェクトを追加、その際に上記で作成した地点を選択して保存
							</p>
							<p>
								4.
								プロジェクトにユーザをアサインする方法は以下の3通りあります。
							</p>
							<p>
								<span style="display: block; font-size: 14px; margin-left: 10px; margin-bottom: 10px; color: #666">- 該当ユーザが本アプリケーションにまだ登録していない場合</span>
								<span style="display: block; font-size: 14px; margin-left: 30px; margin-bottom: 10px;">・「プロジェクト一覧」より、該当プロジェクトの横の「招待」ボタンからユーザを招待</span>
								<span style="display: block; font-size: 14px; margin-left: 10px; margin-bottom: 10px; color: #666">- 該当ユーザが既に本アプリケーションに登録済みでログイン出来る状態の場合</span>
								<span style="display: block; font-size: 14px; margin-left: 30px; margin-bottom: 10px;">・「ユーザ一覧」より、任意のユーザーを選択して上記で作成したプロジェクトを選択して保存</span>
								<span style="display: block; font-size: 14px; margin-left: 30px; margin-bottom: 10px;">・「プロジェクト一覧」より、該当プロジェクトの招待コードをユーザに知らせ、ユーザ自身がマイページから招待コードを入力して保存</span>
							</p>
							<h4>
								上記の一連の作業により、ユーザがプロジェクトにアサインされ、
								そのプロジェクトに登録されている地点に関連付けられているカメラの画像を全て閲覧することが出来るようになります
							</h4>
						</div>
						<div style="margin-top: 20px;">
							<h4>
								【SMSコマンドご利用の事前設定】
								<ol>
								<li>SMS対応のSIM → FieldCamにセット</li>
								<li>FieldCamのSIM電話番号 → FieldShare管理画面のカメラ一覧から登録</li>
								<li>SMS発信のスマホ電話番号 → FieldCamに登録</li>
								<li>FieldCam の[SMS設定]を常時などに設定</li>
								</ol>
							</h4>
						</div>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn text @click="dialog.value = false">閉じる</v-btn>
						</v-card-actions>
					</v-card>
				</template>
			</v-dialog>
		</div>
		<!-- modal END-->
	</v-app>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	components: {
		Loading,
	},
	computed: {},
	data() {
		return {
			drawer: null,
			help: false,
			logoutDialog: false,
			isLoading: this.$store.state.isLoading,
			menu: [
				{ id: "Project", name: "プロジェクト一覧" },
				{ id: "Place", name: "地点一覧" },
				{ id: "Camera", name: "カメラ一覧" },
				{ id: "User", name: "ユーザ一覧" },
			],
			login: this.$store.state.user.data,
		};
	},
	mounted() {
		this.scrollTop();
	},
	methods: {
		logout: function () {
			this.logoutDialog = false;
			this.isLoading = true;
			if (this.$store.state.auth.data.tokenType == "azure") {
				this.$store.dispatch("auth/unsetData");
				this.$store.dispatch("user/unsetData");
				this.$store.dispatch("project/unsetData");
				this.$store.dispatch("places/unsetData");
				location.href =
					"https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
					this.config.encodedLoginUrl;
			} else if (this.$store.state.auth.data.tokenType =="line") {
				this.$store.dispatch("auth/unsetData");
				this.$store.dispatch("user/unsetData");
				this.$store.dispatch("project/unsetData");
				this.$store.dispatch("places/unsetData");
				axios.defaults.headers.common["Authorization"] =
					"Bearer " + this.$store.state.auth.data.token;
				axios
					.get(
						"https://" +
							this.config.defaultOriginString +
							"/api/logout?tokenType=" +
							this.$store.state.auth.data.tokenType
					)
					.then((res) => {
						this.isLoading = false;
						// 成功した場合
						if (res && res.data && res.data.status == "success") {
							console.log("Success: " + JSON.stringify(res.data));
						} else {
							console.log("Fail: " + JSON.stringify(res));
						}
						this.$router.push({ name: "Login" }).catch(() => {});
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.$store.dispatch("auth/unsetData");
				this.$store.dispatch("user/unsetData");
				this.$store.dispatch("project/unsetData");
				this.$store.dispatch("places/unsetData");
				location.href =
					"https://" + this.config.defaultOriginString + "/login";
			}
		},
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		clickRow(it) {
			this.$router.push({ name: "Admin" + it.id }).catch(() => {});
		},
		triggerLoading(b) {
			if (b == "true" || b == true) {
				this.isLoading = true;
			} else {
				this.isLoading = false;
			}
		},
	},
};
</script>

